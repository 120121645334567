import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Layout, X } from 'lucide-react';

// Yardımcı bileşenler
const ImagePreview = ({ url }) => {
  const [isValid, setIsValid] = useState(true);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!url) return;
    const img = new Image();
    img.onload = () => {
      setIsValid(true);
      setLoading(false);
    };
    img.onerror = () => {
      setIsValid(false);
      setLoading(false);
    };
    img.src = url;
  }, [url]);

  if (!url) return null;
  if (loading) return <div className="text-white/60">Yükleniyor...</div>;
  if (!isValid) return <div className="text-red-500">Geçersiz resim URL'si!</div>;

  return (
    <div className="mt-2">
      <img src={url} alt="Preview" className="w-16 h-16 object-cover rounded" />
    </div>
  );
};

const FormInput = ({ type = "text", placeholder, value, onChange, className = "" }) => (
  <input
    type={type}
    placeholder={placeholder}
    value={value}
    onChange={onChange}
    className={`w-full bg-black/20 rounded-lg p-3 text-white border border-white/10 placeholder-white/40 ${className}`}
  />
);

const Button = ({ onClick, color = "blue", children, type = "button", className = "" }) => {
  const colors = {
    blue: "bg-[#3a6df0] hover:bg-[#3461d1]",
    red: "text-red-500 hover:text-red-400",
    gray: "bg-white/10 hover:bg-white/20",
  };

  return (
    <button
      type={type}
      onClick={onClick}
      className={`px-3 py-1.5 text-white rounded-lg transition-colors ${colors[color]} ${className}`}
    >
      {children}
    </button>
  );
};

const AdminPanel = ({ isOpen, onClose, onLogout }) => {
  // State tanımlamaları
  const [categories, setCategories] = useState([]);
  const [apps, setApps] = useState([]);
  const [editingCategory, setEditingCategory] = useState(null);
  const initialCategoryState = { 
    name: '', 
    icon: '', 
    iconUrl: '', 
    title: '', 
    parent: null, 
    isParent: false,
    order: 0 
  };
  const initialAppState = {
    name: '',
    icon: '',
    iconUrl: '',
    bgColor: 'from-[#061e26]/80 to-[#0a3544]/80',
    website: { status: true, url: '' },
    category: ''
  };
  const [newCategory, setNewCategory] = useState(initialCategoryState);
  const [newApp, setNewApp] = useState(initialAppState);

  // Veri yükleme fonksiyonları
  useEffect(() => {
    if (isOpen) {
      fetchData();
    }
  }, [isOpen]);

  const fetchData = async () => {
    try {
      // Kategorileri ve uygulamaları çek
      const [categoriesRes, appsRes] = await Promise.all([
        axios.get('https://koswog.com/api/categories'),
        axios.get('https://koswog.com/api/apps')
      ]);
  
      // Kategorileri işle ve parent referanslarını düzelt
      const processedCategories = categoriesRes.data.map(category => {
        if (category.parent && typeof category.parent === 'string') {
          const parentCategory = categoriesRes.data.find(c => c._id === category.parent);
          return { ...category, parent: parentCategory };
        }
        return category;
      });
  
      setCategories(processedCategories);
      setApps(appsRes.data);
    } catch (error) {
      console.error('Veri yükleme hatası:', error);
    }
  };

  // Form işleyicileri
  const handleSubmit = async (e, type, data, resetData) => {
    e.preventDefault();
    const endpoints = {
      category: 'categories',
      app: 'apps',
      categoryUpdate: `categories/${editingCategory?._id}`
    };
    
    try {
      const method = type === 'categoryUpdate' ? 'put' : 'post';
      const endpoint = endpoints[type];
      await axios[method](`https://koswog.com/api/${endpoint}`, data);
      
      if (type === 'categoryUpdate') {
        setEditingCategory(null);
      } else {
        resetData();
      }
      
      fetchData();
    } catch (error) {
      console.error(`${type} işlemi başarısız:`, error);
      alert(error.response?.data?.message || 'Bir hata oluştu');
    }
  };

  const handleDelete = async (id, type) => {
    if (!window.confirm('Bu öğeyi silmek istediğinizden emin misiniz?')) {
      return;
    }
    
    try {
      if (type === 'categories') {
        // Silinecek kategoriyi bul
        const category = categories.find(c => c._id === id);
        
        if (!category) {
          alert('Kategori bulunamadı!');
          return;
        }
  
        if (category.isParent) {
          // Alt kategorileri kontrol et
          const childCategories = categories.filter(c => c.parent === id);
          if (childCategories.length > 0) {
            const childNames = childCategories.map(c => c.name).join(', ');
            alert(`Bu kategori şu alt kategorilere sahip: ${childNames}\nÖnce bu alt kategorileri silmelisiniz.`);
            return;
          }
        }
  
        // Kategoriye ait uygulamaları kontrol et
        const appsInCategory = apps.filter(app => app.category === id);
        if (appsInCategory.length > 0) {
          const appNames = appsInCategory.map(app => app.name).join(', ');
          alert(`Bu kategoride şu uygulamalar var: ${appNames}\nÖnce bu uygulamaları silmelisiniz.`);
          return;
        }
      }
  
      // Silme işlemini gerçekleştir
      await axios.delete(`https://koswog.com/api/${type}/${id}`);
      await fetchData(); // Verileri yenile
      
    } catch (error) {
      console.error(`${type} silme hatası:`, error);
      alert(error.response?.data?.message || 'Silme işlemi başarısız');
    }
  };

  // Logout işleyicisi
  const handleLogout = () => {
    onLogout();
    onClose();
  };
  // Kategori render fonksiyonları
  const renderCategories = () => {
    // Üst kategorileri filtrele
    const parentCategories = categories.filter(cat => cat.isParent);
  
    return (
      <div className="space-y-4">
        {parentCategories.map(parent => {
          // Her üst kategori için alt kategorileri bul
          const childCategories = categories.filter(cat => cat.parent?._id === parent._id || cat.parent === parent._id);
          
          return (
            <div key={parent._id} className="bg-black/10 rounded-lg">
              {/* Üst Kategori */}
              <div className="bg-black/20 p-3 flex items-center justify-between">
                <div className="flex items-center gap-2">
                  {parent.iconUrl ? (
                    <img 
                      src={parent.iconUrl} 
                      alt={parent.name}
                      className="w-6 h-6 object-cover rounded"
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = '';
                      }}
                    />
                  ) : (
                    <Layout className="w-6 h-6 text-white/60" />
                  )}
                  <div>
                    <span className="text-white/90 font-medium">{parent.name}</span>
                    {parent.title && (
                      <span className="text-white/50 text-sm ml-2">({parent.title})</span>
                    )}
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  <button
                    onClick={() => setEditingCategory(parent)}
                    className="px-3 py-1 text-blue-400 hover:text-blue-300 transition-colors text-sm"
                  >
                    Düzenle
                  </button>
                  <button
                    onClick={() => handleDelete(parent._id, 'categories')}
                    className="px-3 py-1 text-red-500 hover:text-red-400 transition-colors text-sm"
                  >
                    Sil
                  </button>
                </div>
              </div>
  
              {/* Alt Kategoriler */}
              <div className="p-2">
                {childCategories.length > 0 ? (
                  <div className="space-y-2">
                    {childCategories.map(child => (
                      <div 
                        key={child._id} 
                        className="ml-4 flex items-center justify-between p-2 bg-black/10 rounded-lg border border-white/5"
                      >
                        <div className="flex items-center gap-2">
                          {child.iconUrl ? (
                            <img 
                              src={child.iconUrl} 
                              alt={child.name}
                              className="w-5 h-5 object-cover rounded"
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = '';
                              }}
                            />
                          ) : (
                            <Layout className="w-5 h-5 text-white/60" />
                          )}
                          <div>
                            <span className="text-white/80">{child.name}</span>
                            {child.title && (
                              <span className="text-white/40 text-sm ml-2">({child.title})</span>
                            )}
                          </div>
                        </div>
                        <div className="flex items-center gap-2">
                          <button
                            onClick={() => setEditingCategory(child)}
                            className="px-2 py-1 text-blue-400 hover:text-blue-300 transition-colors text-sm"
                          >
                            Düzenle
                          </button>
                          <button
                            onClick={() => handleDelete(child._id, 'categories')}
                            className="px-2 py-1 text-red-500 hover:text-red-400 transition-colors text-sm"
                          >
                            Sil
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="ml-4 py-2 px-3 text-white/40 text-sm italic">
                    Alt kategori bulunmuyor
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  // Form render fonksiyonları
  const renderCategoryForm = (isEditing = false) => {
    const formData = isEditing ? editingCategory : newCategory;
    const setFormData = isEditing ? setEditingCategory : setNewCategory;

    return (
      <div className="mb-8">
        <h3 className="text-white/80 text-lg mb-4">
          {isEditing ? 'Kategoriyi Düzenle' : 'Yeni Kategori Ekle'}
        </h3>
        <form
          onSubmit={(e) =>
            handleSubmit(
              e,
              isEditing ? 'categoryUpdate' : 'category',
              formData,
              () => {
                setFormData(initialCategoryState);
                if (isEditing) setEditingCategory(null);
              }
            )
          }
          className="space-y-4"
        >
          <FormInput
            placeholder="Kategori Adı"
            value={formData.name}
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
          />
          <FormInput
            placeholder="İkon"
            value={formData.icon}
            onChange={(e) => setFormData({ ...formData, icon: e.target.value })}
          />
          <div className="space-y-2">
            <FormInput
              placeholder="İkon URL (resim adresi)"
              value={formData.iconUrl}
              onChange={(e) => setFormData({ ...formData, iconUrl: e.target.value })}
            />
            <ImagePreview url={formData.iconUrl} />
          </div>
          <FormInput
            placeholder="Başlık"
            value={formData.title}
            onChange={(e) => setFormData({ ...formData, title: e.target.value })}
          />
          <div className="flex items-center gap-2">
            <input
              type="checkbox"
              checked={formData.isParent}
              onChange={(e) => setFormData({ ...formData, isParent: e.target.checked })}
              className="w-5 h-5 rounded border-white/10 bg-black/20"
            />
            <span className="text-white/80">Üst Kategori mi?</span>
          </div>
          {!formData.isParent && (
            <select
              value={formData.parent || ''}
              onChange={(e) => setFormData({ ...formData, parent: e.target.value })}
              className="w-full bg-black/20 rounded-lg p-3 text-white border border-white/10"
            >
              <option value="">Üst Kategori Seçin</option>
              {categories
                .filter((c) => c.isParent)
                .map((parentCategory) => (
                  <option key={parentCategory._id} value={parentCategory._id}>
                    {parentCategory.name}
                  </option>
                ))}
            </select>
          )}
          <FormInput
            type="number"
            placeholder="Sıralama (0-99)"
            value={formData.order}
            onChange={(e) => setFormData({ ...formData, order: parseInt(e.target.value) || 0 })}
          />
          <div className="flex gap-2">
            <Button type="submit" color="blue">
              {isEditing ? 'Kategoriyi Güncelle' : 'Kategori Ekle'}
            </Button>
            {isEditing && (
              <Button onClick={() => setEditingCategory(null)} color="gray">
                İptal
              </Button>
            )}
          </div>
        </form>
      </div>
    );
  };

  const renderAppForm = () => (
    <div className="mb-8">
      <h3 className="text-white/80 text-lg mb-4">Yeni Uygulama Ekle</h3>
      <form onSubmit={(e) => handleSubmit(
        e,
        'app',
        newApp,
        () => setNewApp(initialAppState)
      )} className="space-y-4">
        <FormInput
          placeholder="Uygulama Adı"
          value={newApp.name}
          onChange={(e) => setNewApp({ ...newApp, name: e.target.value })}
        />
        <FormInput
          placeholder="İkon"
          value={newApp.icon}
          onChange={(e) => setNewApp({ ...newApp, icon: e.target.value })}
        />
        <div className="space-y-2">
          <FormInput
            placeholder="İkon URL (resim adresi)"
            value={newApp.iconUrl}
            onChange={(e) => setNewApp({ ...newApp, iconUrl: e.target.value })}
          />
          <ImagePreview url={newApp.iconUrl} />
        </div>
        <FormInput
          placeholder="Arkaplan Rengi (örn: from-[#061e26]/80 to-[#0a3544]/80)"
          value={newApp.bgColor}
          onChange={(e) => setNewApp({ ...newApp, bgColor: e.target.value })}
        />
        <FormInput
          placeholder="Website URL"
          value={newApp.website.url}
          onChange={(e) => setNewApp({
            ...newApp,
            website: { ...newApp.website, url: e.target.value }
          })}
        />
        <div className="flex items-center gap-2">
          <input
            type="checkbox"
            checked={newApp.website.status}
            onChange={(e) => setNewApp({
              ...newApp,
              website: { ...newApp.website, status: e.target.checked }
            })}
            className="w-5 h-5 rounded border-white/10 bg-black/20"
          />
          <span className="text-white/80">Website Aktif</span>
        </div>
        <select
          value={newApp.category}
          onChange={(e) => setNewApp({ ...newApp, category: e.target.value })}
          className="w-full bg-black/20 rounded-lg p-3 text-white border border-white/10"
        >
          <option value="">Kategori Seçin</option>
          {categories.filter(c => !c.isParent).map(category => {
            const parentCategory = categories.find(c => c._id === category.parent);
            return (
              <option key={category._id} value={category._id}>
                {parentCategory ? `${parentCategory.name} > ${category.name}` : category.name}
              </option>
            );
          })}
        </select>
        <Button type="submit" color="blue">Uygulama Ekle</Button>
      </form>
    </div>
  );

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50">
      <div className="bg-[#1a1c30] rounded-xl p-6 w-[800px] h-[80vh] overflow-y-auto shadow-xl border border-white/10 relative">
        {/* Kapatma butonu */}
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-white/60 hover:text-white/80 transition-colors"
        >
          <X size={24} />
        </button>

        {/* Üst bar - Logout butonu */}
        <div className="mb-6 flex justify-between items-center">
          <h2 className="text-white/90 text-xl">Admin Panel</h2>
          <Button onClick={handleLogout} color="red">Çıkış Yap</Button>
        </div>

        {/* Ana içerik */}
        <div className="space-y-8">
          {editingCategory && renderCategoryForm(true)}
          {renderCategoryForm()}
          {renderAppForm()}

          <div className="grid grid-cols-2 gap-6">
            <div>
              <h3 className="text-white/80 text-lg mb-4">Mevcut Kategoriler</h3>
              {renderCategories()}
            </div>
            <div>
              <h3 className="text-white/80 text-lg mb-4">Mevcut Uygulamalar</h3>
              <div className="space-y-2">
                {apps.map(app => (
                  <div key={app._id} className="flex justify-between items-center p-2 bg-black/20 rounded-lg">
                    <div className="flex items-center gap-2">
                      {app.iconUrl ? (
                        <img 
                          src={app.iconUrl} 
                          alt={app.name}
                          className="w-4 h-4 object-cover"
                        />
                      ) : (
                        <span className="text-white/60">{app.icon}</span>
                      )}
                      <span className="text-white/80">{app.name}</span>
                      {app.category && (
                        <span className="text-white/40 text-sm">
                          ({categories.find(c => c._id === app.category)?.name})
                        </span>
                      )}
                    </div>
                    <button 
                      onClick={() => handleDelete(app._id, 'apps')}
                      className="text-red-500 hover:text-red-400"
                    >
                      Sil
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminPanel;