import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { 
  LayoutGrid as Grid,
  Bell, 
  Layout,
  Search,
  Box,
  Film,
  Music,
  Code,
  BookOpen,
  Gamepad2,
  Sun,
  Moon,
  MoreVertical,
  User,
  Home,
  Star,
  Zap,
  Share2
} from 'lucide-react';
import LoginModal from './components/LoginModal';
import AdminPanel from './components/AdminPanel';

// BackgroundVideo component
const BackgroundVideo = ({ isLightMode }) => {
  const videoRef = React.useRef(null);

  React.useEffect(() => {
    if (videoRef.current) {
      videoRef.current.play().catch(error => {
        console.log("Video autoplay failed:", error);
      });
    }
  }, []);

  return (
    <div className="fixed inset-0 flex items-center justify-center -z-10">
      <video
        ref={videoRef}
        autoPlay
        loop
        muted
        playsInline
        className="w-full h-full object-cover"
      >
        <source src="/video.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

// AppCard component
const AppCard = ({ app, isLightMode }) => {
  const [imageError, setImageError] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);

  const handleShare = () => {
    if (navigator.share) {
      navigator.share({
        title: app.name,
        text: `Check out ${app.name}!`,
        url: app.website.url
      })
      .then(() => console.log('Shared successfully'))
      .catch((error) => console.log('Error sharing:', error));
    }
  };

  return (
    <div className={`relative flex items-center justify-between p-4 mb-2 rounded-xl bg-gradient-to-r ${app.bgColor || 'from-[#061e26]/80 to-[#0a3544]/80'} 
      backdrop-blur-xl hover:scale-[1.02] transition-all group
      ${isLightMode ? 'hover:shadow-lg' : ''}`}>
      <div className="flex items-center gap-3">
        <div className="w-8 h-8 rounded flex items-center justify-center text-white text-sm font-medium">
          {app.iconUrl && !imageError ? (
            <img 
              src={app.iconUrl} 
              alt={app.name}
              className="w-6 h-6 object-cover"
              onError={() => setImageError(true)}
            />
          ) : (
            app.icon
          )}
        </div>
        <span className={isLightMode ? "text-gray-800" : "text-white/90"}>{app.name}</span>
      </div>
      <div className="flex items-center gap-4">
        <div className="flex items-center gap-2">
          <span className={`w-1.5 h-1.5 rounded-full ${app.website?.status ? 'bg-green-500' : 'bg-white/30'}`}></span>
          <span className={isLightMode ? "text-gray-600 text-sm" : "text-white/60 text-sm"}>
            {app.website?.status ? 'Website Online' : 'Website Offline'}
          </span>
        </div>
        <a 
          href={app.website?.url} 
          target="_blank" 
          rel="noopener noreferrer"
          className="px-4 py-1.5 rounded-full bg-[#3a6df0] text-white text-sm hover:bg-[#3461d1] transition-colors"
        >
          Open
        </a>
        <div className="relative">
          <button 
            onClick={() => setShowDropdown(!showDropdown)}
            className={`p-1 rounded-full transition-colors ${
              isLightMode 
                ? 'text-gray-600 hover:bg-gray-200/50' 
                : 'text-white/60 hover:text-white/80 hover:bg-white/10'
            }`}
          >
            <MoreVertical size={16} />
          </button>
          
          {showDropdown && (
            <div className={`absolute right-0 mt-2 w-48 rounded-lg shadow-lg py-1 z-10 ${
              isLightMode 
                ? 'bg-white border border-gray-200' 
                : 'bg-[#1a1c30] border border-white/10'
            }`}>
              <button
                onClick={handleShare}
                className={`w-full px-4 py-2 text-left flex items-center gap-2 transition-colors ${
                  isLightMode 
                    ? 'text-gray-700 hover:bg-gray-100' 
                    : 'text-white/80 hover:bg-white/10'
                }`}
              >
                <Share2 size={16} />
                <span>Share</span>
              </button>
              <button
                onClick={() => window.open(app.website?.url, '_blank')}
                className={`w-full px-4 py-2 text-left flex items-center gap-2 transition-colors ${
                  isLightMode 
                    ? 'text-gray-700 hover:bg-gray-100' 
                    : 'text-white/80 hover:bg-white/10'
                }`}
              >
                <Star size={16} />
                <span>Add to favorites</span>
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

// Main App component
const App = () => {
  // State definitions
  const [isLightMode, setIsLightMode] = useState(false);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [isAdminPanelOpen, setIsAdminPanelOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [apps, setApps] = useState([]);
  const [categories, setCategories] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [hierarchicalCategories, setHierarchicalCategories] = useState([]);
  const [notifications, setNotifications] = useState([
    { id: 1, message: "Welcome to the app!" },
    { id: 2, message: "New features available" },
    { id: 3, message: "Check out our latest updates" }
  ]);
  const [showNotifications, setShowNotifications] = useState(false);

  // Tema değişikliğini izle ve uygula
  useEffect(() => {
    const root = document.documentElement;
    if (isLightMode) {
      root.classList.add('light-mode');
    } else {
      root.classList.remove('light-mode');
    }
  }, [isLightMode]);

  // Icon mapping
  const icons = {
    Layout,
    Search,
    Grid,
    Box,
    Film,
    Music,
    Code,
    Book: BookOpen,
    Game: Gamepad2,
    Home,
    Star,
    Zap
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log('Fetching data...');
        
        const categoriesResponse = await axios.get('https://koswog.com/api/categories');
        console.log('Categories response:', categoriesResponse.data);
        setCategories(categoriesResponse.data);
        
        const hierarchicalResponse = await axios.get('https://koswog.com/api/categories/hierarchical');
        console.log('Hierarchical response:', hierarchicalResponse.data);
        if (Array.isArray(hierarchicalResponse.data)) {
          setHierarchicalCategories(hierarchicalResponse.data);
        } else {
          console.error('Hierarchical data is not an array:', hierarchicalResponse.data);
        }

        const appsResponse = await axios.get('https://koswog.com/api/apps');
        console.log('Apps response:', appsResponse.data);
        setApps(appsResponse.data);
        
      } catch (error) {
        console.error('Error fetching data:', error);
        if (error.response) {
          console.error('Error response:', error.response.data);
        }
      }
    };

    fetchData();
    
  }, [isAdminPanelOpen]);

  // Search and filtering
  const filteredApps = apps.filter(app => {
    const matchesSearch = app.name.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesCategory = !selectedCategory || 
      app.category?._id === selectedCategory ||
      categories.find(c => c._id === app.category?._id)?.parent === selectedCategory;
    return matchesSearch && matchesCategory;
  });

  // Handle login/logout
  const handleLogin = () => {
    setIsLoggedIn(true);
    setIsLoginModalOpen(false);
    setIsAdminPanelOpen(true);
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    setIsAdminPanelOpen(false);
  };

  // Category render functions
  const renderParentCategory = (category) => {
    const IconComponent = icons[category.icon] || Grid;
    const childCategories = category.children || [];
    
    return (
      <div key={category._id} className="mb-8">
        <div className={`text-sm px-2 mb-4 flex items-center gap-2 ${
          isLightMode ? 'text-gray-500' : 'text-white/40'
        }`}>
          {category.iconUrl ? (
            <img 
              src={category.iconUrl} 
              alt={category.name}
              className="w-4 h-4 object-cover"
              onError={(e) => {
                e.target.onerror = null;
                e.target.style.display = 'none';
                const iconSpan = document.createElement('span');
                iconSpan.innerHTML = `<${IconComponent} size={16} />`;
                e.target.parentNode.insertBefore(iconSpan, e.target);
              }}
            />
          ) : (
            <IconComponent size={16} className={isLightMode ? 'text-gray-400' : 'text-white/60'} />
          )}
          <span>{category.name}</span>
          {category.title && <span className="ml-2">({category.title})</span>}
        </div>
        <div className="space-y-2">
          {childCategories.map(child => renderChildCategory(child))}
        </div>
      </div>
    );
  };

  const renderChildCategory = (category) => {
    const IconComponent = icons[category.icon] || Grid;
    
    return (
      <button
        key={category._id}
        onClick={() => setSelectedCategory(category._id)}
        className={`w-full flex items-center p-2 rounded-md transition-colors group
          ${selectedCategory === category._id 
            ? (isLightMode ? 'bg-gray-200/50' : 'bg-white/10')
            : ''
          }
          ${isLightMode 
            ? 'text-gray-700 hover:bg-gray-200/50' 
            : 'text-white/80 hover:bg-white/10'
          }`}
      >
        <span className={`transition-colors ${
          isLightMode 
            ? 'text-gray-500 group-hover:text-gray-700' 
            : 'text-white/60 group-hover:text-white/80'
        }`}>
          {category.iconUrl ? (
            <img
              src={category.iconUrl}
              alt={category.name}
              className="w-4 h-4 object-cover"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = '';
              }}
            />
          ) : (
            <IconComponent size={16} />
          )}
        </span>
        <span className="ml-2 text-sm">{category.name}</span>
        {category.title && (
          <span className={`ml-2 text-xs ${
            isLightMode ? 'text-gray-500' : 'text-white/40'
          }`}>{category.title}</span>
        )}
      </button>
    );
  };

  return (
    <div className={`min-h-screen w-full p-4 flex items-center justify-center transition-colors duration-200
      ${isLightMode ? 'bg-gradient-to-br from-white/20 via-gray-100/20 to-gray-200/20' : 
      'bg-gradient-to-br from-blue-500/20 via-purple-500/20 to-red-500/20'}`}>
      <BackgroundVideo isLightMode={isLightMode} />

      <LoginModal 
        isOpen={isLoginModalOpen}
        onClose={() => setIsLoginModalOpen(false)}
        onLogin={handleLogin}
        isLightMode={isLightMode}
      />

      <AdminPanel 
        isOpen={isAdminPanelOpen}
        onClose={() => setIsAdminPanelOpen(false)}
        onLogout={handleLogout}
        isLightMode={isLightMode}
      />
      
      <div className={`w-full max-w-[1250px] h-[90vh] max-h-[860px] rounded-xl backdrop-blur-xl 
        ${isLightMode 
          ? 'bg-white/60 border-gray-200/50' 
          : 'bg-[#10121b]/60 border-white/10'
        }
        flex flex-col overflow-hidden relative shadow-2xl border transition-colors duration-200`}>
        
        {/* Header */}
        <header className={`h-[58px] px-6 flex items-center border-b transition-colors duration-200
          ${isLightMode 
            ? 'border-gray-200/50 bg-white/10' 
            : 'border-white/10 bg-black/10'
          }`}>
          <div className="flex gap-2 mr-8">
            <div className="w-3 h-3 rounded-full bg-[#f96057] hover:opacity-80 cursor-pointer transition-opacity"></div>
            <div className="w-3 h-3 rounded-full bg-[#f8ce52] hover:opacity-80 cursor-pointer transition-opacity"></div>
            <div className="w-3 h-3 rounded-full bg-[#5fcf65] hover:opacity-80 cursor-pointer transition-opacity"></div>
          </div>

          <nav className="flex items-center">
            <button className={`px-6 py-5 transition-colors ${
              isLightMode 
                ? 'text-gray-800 border-gray-800' 
                : 'text-white/90 border-white/90'
              } border-b-2 hover:text-gray-900`}>
              Sites
            </button>
            <button className={`px-6 py-5 relative transition-colors ${
              isLightMode 
                ? 'text-gray-600 hover:text-gray-800' 
                : 'text-white/60 hover:text-white/90'
            }`}>
              Market
              <span className="absolute right-4 top-4 w-1.5 h-1.5 rounded-full bg-[#3a6df0]"></span>
            </button>
          </nav>

          <div className="flex-1 max-w-[400px] mx-auto">
            <div className="relative">
              <Search className={`absolute left-3 top-1/2 transform -translate-y-1/2 w-4 h-4 ${
                isLightMode ? 'text-gray-400' : 'text-white/40'
              }`} />
              <input 
                type="text"
                placeholder="Search apps..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className={`w-full h-10 rounded-lg pl-10 pr-4 border transition-colors
                  ${isLightMode 
                    ? 'bg-white/20 text-gray-800 border-gray-200/50 placeholder-gray-400 focus:border-gray-300' 
                    : 'bg-black/20 text-white border-white/10 placeholder-white/40 focus:border-white/20'
                  }`}
              />
            </div>
          </div>

          <div className="flex items-center ml-auto">
            <div className="relative">
              <button
                onClick={() => setShowNotifications(!showNotifications)}
                className="relative"
              >
                <Bell className={`w-6 h-6 cursor-pointer transition-colors ${
                  isLightMode 
                    ? 'text-gray-600 hover:text-gray-800' 
                    : 'text-white/80 hover:text-white'
                }`} />
                <span className="absolute -top-1 -right-1 w-4 h-4 bg-[#3a6df0] rounded-full flex items-center justify-center text-xs text-white">
                  {notifications.length}
                </span>
              </button>

              {showNotifications && (
                <div className={`absolute right-0 mt-2 w-64 rounded-lg shadow-lg py-2 z-10 ${
                  isLightMode 
                    ? 'bg-white border-gray-200' 
                    : 'bg-[#1a1c30] border-white/10'
                } border`}>
                  {notifications.map(notification => (
                    <div key={notification.id} className={`px-4 py-2 ${
                      isLightMode 
                        ? 'text-gray-700 hover:bg-gray-100' 
                        : 'text-white/80 hover:bg-white/10'
                    }`}>
                      {notification.message}
                    </div>
                  ))}
                </div>
              )}
            </div>
            
            <button 
              onClick={() => setIsLoginModalOpen(true)}
              className={`w-8 h-8 rounded-full flex items-center justify-center ml-4 transition-colors ${
                isLightMode 
                  ? 'bg-gray-200/50 hover:bg-gray-200' 
                  : 'bg-white/10 hover:bg-white/20'
              }`}
            >
              <User size={18} className={isLightMode ? "text-gray-600" : "text-white/80"} />
            </button>
          </div>
        </header>

        <div className="flex flex-1 overflow-hidden">
          {/* Left Sidebar */}
          <aside className={`w-48 p-4 overflow-auto flex-shrink-0 border-r transition-colors duration-200
            ${isLightMode 
              ? 'border-gray-200/50 bg-white/10' 
              : 'border-white/10 bg-black/10'
            }`}>
            <div>
              <div className={isLightMode ? "text-gray-500 text-sm mb-4" : "text-white/40 text-sm mb-4"}>
                Categories
              </div>
              <div className="space-y-2 mb-8">
                <button
                  onClick={() => setSelectedCategory(null)}
                  className={`w-full flex items-center p-2 rounded-md transition-colors group
                    ${!selectedCategory 
                      ? (isLightMode ? 'bg-gray-200/50' : 'bg-white/10') 
                      : ''
                    }
                    ${isLightMode 
                      ? 'text-gray-700 hover:bg-gray-200/50' 
                      : 'text-white/80 hover:bg-white/10'
                    }`}
                >
                  <span className={`transition-colors ${
                    isLightMode 
                      ? 'text-gray-500 group-hover:text-gray-700' 
                      : 'text-white/60 group-hover:text-white/80'
                  }`}>
                    <Grid size={16} />
                  </span>
                  <span className="ml-2 text-sm">All Links</span>
                </button>
              </div>
            
              {hierarchicalCategories.map(category => renderParentCategory(category))}
            </div>
          </aside>

          {/* Main Content */}
          <main className="flex-1 p-6 overflow-auto">
            <section>
              <h2 className={`text-sm font-medium mb-4 ${
                isLightMode ? 'text-gray-500' : 'text-white/60'
              }`}>
                {selectedCategory 
                  ? categories.find(c => c._id === selectedCategory)?.name
                  : 'All Apps'}
              </h2>
              <div>
                {filteredApps.map((app) => (
                  <AppCard key={app._id} app={app} isLightMode={isLightMode} />
                ))}
              </div>
            </section>
          </main>
        </div>
      </div>

      {/* Theme toggle button */}
      <button
        onClick={() => setIsLightMode(!isLightMode)}
        className={`fixed bottom-8 right-8 w-10 h-10 backdrop-blur-md rounded-full 
          flex items-center justify-center shadow-lg transition-colors border z-50
          ${isLightMode 
            ? 'bg-gray-200/50 hover:bg-gray-200 border-gray-200' 
            : 'bg-white/10 hover:bg-white/20 border-white/20'
          }`}
      >
        {isLightMode ? (
          <Moon size={18} className="text-gray-800" />
        ) : (
          <Sun size={18} className="text-white" />
        )}
      </button>
    </div>
  );
};

export default App;